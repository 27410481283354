.secure-field * {
  box-sizing: border-box;
}

.secure-field.secure-field__base {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  -webkit-flex-flow: column nowrap;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
}

.secure-field.secure-field__base .secure-field--input-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.secure-field.secure-field__base.secure-field__input .secure-field--input-container {
  position: relative;
}

.secure-field.secure-field__base.secure-field__input .secure-field--input {
  display: inline-block;
  width: 100%;
  padding: 12px 16px 10px;
  line-height: 1.3;
  cursor: text;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, -webkit-transform 200ms ease;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  -webkit-transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, -webkit-transform 200ms ease;
}

.secure-field.secure-field__base.secure-field__input .secure-field--input::-moz-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input:-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::-webkit-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::placeholder {
  transition: color 200ms ease;
  -webkit-transition: color 200ms ease;
}

.secure-field.secure-field__base.secure-field__input .secure-field--input {
  color: #4a4a50;
  background-color: #ffffff;
  border-color: #c6c6cb;
}

.secure-field.secure-field__base.secure-field__input .secure-field--input::-moz-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input:-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::-webkit-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input .secure-field--input::placeholder {
  color: #c6c6cb;
}

.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input {
  color: #c6c6cb;
  cursor: default;
  background-color: pink;
  border-color: pink;
}

.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::-moz-selection,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input ::-moz-selection,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::selection,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input ::selection {
  background-color: transparent;
}

.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::-moz-placeholder,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input:-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::-webkit-input-placeholder,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::-ms-input-placeholder,
.secure-field.secure-field__base.secure-field__input.secure-field__is-disabled.secure-field .secure-field--input::placeholder {
  color: transparent;
}

.secure-field.secure-field__base.secure-field__has-actions .secure-field--actions {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
  pointer-events: none;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
}

.secure-field.secure-field__base.secure-field__has-actions .secure-field--action {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 100%;
  padding: 12px 0 10px;
  pointer-events: auto;
  cursor: pointer;
  transition: color 200ms ease;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-transition: color 200ms ease;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  justify-content: center;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--action.secure-field--action__card-icon {
  width: 40px;
  height: 100%;
  padding: 0;
  pointer-events: none;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--action-card-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  max-width: 100%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__empty {
  z-index: 1;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__error {
  z-index: 3;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__recognized-card {
  z-index: 4;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__empty,
.secure-field.secure-field__base.secure-field__has-card-icon.secure-field__has-error.secure-field .secure-field--card-icon.secure-field--card-icon__error,
.secure-field.secure-field__base.secure-field__has-card-icon.secure-field__is-recognized .secure-field--card-icon.secure-field--card-icon__recognized-card {
  opacity: 1;
  visibility: inherit;
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  -webkit-transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
}

.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__error,
.secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon.secure-field--card-icon__recognized-card,
.secure-field.secure-field__base.secure-field__has-card-icon.secure-field__has-error.secure-field .secure-field--card-icon.secure-field--card-icon__empty,
.secure-field.secure-field__base.secure-field__has-card-icon.secure-field__is-recognized .secure-field--card-icon.secure-field--card-icon__empty,
.secure-field.secure-field__base.secure-field__has-card-icon.secure-field__is-recognized .secure-field--card-icon.secure-field--card-icon__error {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  -webkit-transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
}