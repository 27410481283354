body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.inline-wrapper { border: 1px solid #c6c6cb; display: flex; }
.inline-wrapper.field__has-error { border-color: #e1114e; }
.inline-wrapper > div { display: flex; }

#expiry,
.inline-wrapper .secure-field.secure-field__base.secure-field__input .secure-field--input { border: 0 }


.inline-wrapper.secure-field__has-focus-cvv .secure-field.secure-field__base.secure-field__has-card-icon .secure-field--card-icon { opacity: 0; visibility: hidden; }
.inline-wrapper.field__has-error .secure-field.secure-field__base.secure-field__has-card-icon.secure-field .secure-field--card-icon.secure-field--card-icon__error,
.inline-wrapper.secure-field__has-focus-cvv .secure-field.secure-field__base.secure-field__has-card-icon .secure-field--cvv-icon { opacity: 1; visibility: visible; }

#expiry { font-size: 16px; font-family:  "Open Sans";  padding: 11px 10px 10px; border: 1px solid #C6C6CB; height: 42px; top: -1px; position: relative; margin-left: 70px; }
#expiry::placeholder { font-size: 17px;}
/* #expiry:focus { outline: none; box-shadow: none; } */

.rsc-ts-bubble p {
  line-height: 1.3em;
}